// extracted by mini-css-extract-plugin
export var articleContainer = "index-module--articleContainer--JAV2X";
export var articleImage = "index-module--articleImage--6HWL2";
export var breadcrumb = "index-module--breadcrumb--kfNaK";
export var canopyGreen = "index-module--canopyGreen--R9L1w";
export var carbonGrey = "index-module--carbonGrey--zYOUo";
export var continueReadingContainer = "index-module--continueReadingContainer--Wsb3M";
export var copyContainer = "index-module--copyContainer--eH56O";
export var dawnPink = "index-module--dawnPink--0Ncz4";
export var headlineContainer = "index-module--headlineContainer--hYciE";
export var hide = "index-module--hide--M6XOK";
export var hiveYellow = "index-module--hiveYellow---WYE8";
export var horizonWhite = "index-module--horizonWhite--p+xzH";
export var nextArticle = "index-module--nextArticle--oRKaC";
export var nextArticleContent = "index-module--nextArticleContent--ZBOv4";
export var nextArticleImage = "index-module--nextArticleImage--Iq4CZ";
export var nextArticles = "index-module--nextArticles--pOboE";
export var oceanicBlue = "index-module--oceanicBlue--bm5Fs";
export var page404Title = "index-module--page404-title--W6eze";
export var show = "index-module--show--TPCQx";
export var simpleColouredCopyBlock = "index-module--simpleColouredCopyBlock--FQqa3";
export var simpleRichTextArea = "index-module--simpleRichTextArea--pHTH-";
export var socialIconsContainer = "index-module--socialIconsContainer--l04QS";
export var stickyHeadlineContainer = "index-module--stickyHeadlineContainer--s4dSV";
export var stickySocialIconsContainer = "index-module--stickySocialIconsContainer--CnAw9";
export var tagsContainer = "index-module--tagsContainer--6Ahcf";