import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby-link";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Moment from "react-moment";
import rightArrowIcon from "../../images/icons/rightArrowIcon.svg";

import {
  continueReadingContainer,
  nextArticles,
  nextArticle,
  nextArticleImage,
  nextArticleContent,
  show,
  hide,
} from "./ContinueReading.module.scss";

const ContinueReading = ({
  allArticles,
  currentArticleId,
  inView,
  continueReadingArticles,
  showNextTwo = false,
}) => {
  const {
    allStrapiInsights: { allInsights },
  } = useStaticQuery(graphql`
    {
      allStrapiInsights {
        allInsights: nodes {
          id
          strapiId
          publish_at
          slug
          shortDesc
          authors {
            id
            name
          }
          media_type {
            id
            tyoe
          }
          image {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.53, width: 122)
                }
              }
            }
          }
        }
      }
    }
  `);

  const { insights } = continueReadingArticles || [];

  const filteredArticles = currentArticleId
    ? allArticles?.filter((article) => article.id != currentArticleId)
    : allArticles;

  const nextTwoArticles = filteredArticles.slice(0, 2);

  const articlesToShow =
    showNextTwo || !insights?.length ? nextTwoArticles : insights;

  const strapiIds = articlesToShow.map((insight) => insight.id);

  const fullArticles = allInsights.filter(
    (article) =>
      strapiIds.includes(article.id) || strapiIds.includes(article.strapiId)
  );

  return (
    <div className={`${continueReadingContainer} ${inView ? show : show}`}>
      <h4>Continue Reading</h4>
      <div className={nextArticles}>
        {fullArticles.map((article, index) => (
          <div
            key={article.id}
            className={nextArticle}
            onClick={() => navigate(`/articles/${article.slug}`)}
            role="button"
            tabIndex={index}
            onKeyDown={(event) => {
              if (event.keycode === 13) navigate(`/articles/${article.slug}`);
            }}
          >
            <GatsbyImage
              image={
                article?.image?.image?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={article?.image?.image?.alternativeText}
              className={nextArticleImage}
            />
            <div className={nextArticleContent}>
              <p className="ui">{article.shortDesc}</p>
              {article?.authors?.length === 0 ? (
                <p className="ui">
                  {article.media_type.tyoe},{" "}
                  <Moment format="D MMM YYYY">{article.publish_at}</Moment>
                </p>
              ) : (
                article?.authors?.map(({ name, id }) => {
                  return (
                    <p key={id} className="ui">
                      {name},{" "}
                      <Moment format="D MMM YYYY">{article.publish_at}</Moment>
                    </p>
                  );
                })
              )}
            </div>
            <img
              src={rightArrowIcon}
              alt="right-arrow-icon"
              width="10"
              heigth="10"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContinueReading;
